export const GlowvaRoutes = {
    HOME: '/home',
    RESOURCES: '/resources',
    COMMUNITY: '/community',
    CONTACT: '/contact',
    FAQ: '/faq',
    SIGN_IN: '/sign-in',
    PASSWORD_RESET: '/password-reset',
    REGISTER1: '/email-verification', // let user input email address and send the email to firebase authentication
    REGISTER2: '/middle-verification', // temporary page for user to click the link in the email
    REGISTER3: '/register', // fill in user information
    CHAT: '/chat',
};

// GlowvaRoutes.RESOURCES
// import { React, useState } from 'react';
import '../css/Chatbot.css';
import { useNavigate } from 'react-router-dom';
import { GlowvaRoutes } from '../config/route.jsx';

function ChatBotDot() {
    // const [isOpen, setIsOpen] = useState(false);

    // const toggleChatbot = () => {
    //     setIsOpen(!isOpen);
    // };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(GlowvaRoutes.CHAT);
    };

    return (
        <>
            <div className="chatbot-container">
                <div className="chatbot-dot" onClick={handleClick} style={{ backgroundImage: `url(/chatbot_icon.svg)` }}></div>
                {/* {isOpen && (
                    <div className="chatbot-window">
                        <p>Hi</p>
                    </div>
                )} */}
            </div>
        </>
    );
}

export default ChatBotDot;